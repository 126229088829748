import React, { } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useNavigate } from 'react-router-dom';
import postcarelogo from '../assets/postcare.png';
import './css/signup.css';

const emailSchema = z.object({
  // email: z.string().email({ message: "Invalid email address" }),
  emailPrefix: z
    .string()
    .nonempty({ message: 'Email prefix is required' })
    .regex(/^[^@]+$/, { message: 'Please do not include "@" symbol' })
    .min(3, { message: 'Minimum 3 characters required for email prefix' })
});

const SignUp = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: zodResolver(emailSchema),
  });

  // Function to handle the email verification
  const onSubmit = async (data) => {
    const fullEmail = `${data.emailPrefix}@gmail.com`;

    // navigate('/signup-verify', { state: { userEmail: data.email } }); // Navigate to the waiting page immediately

    // The email sending process is handled asynchronously but won't block the navigation
    try {
      const response = await fetch(`${process.env.REACT_APP_BASEURL}/auth/sendlink`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: fullEmail }),
      });
      if(response.status===201){
        navigate('/signup-verify', { state: { userEmail: fullEmail } });
      }
      
      else {
        alert('Failed to send verification email. Please try again.');
      }
    } catch (error) {
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <div className="signup-container">
      <h2 className="signup-title">
        Enter your email to register
      </h2>

      <div className="setup-instruction">
        <p>To continue setting up your PostCare.AI account, please enter your email to generate a one-time </p>
        <p style={{ textAlign: 'center' }}>password valid for 24 hours or access the link to set your password.</p>
      </div>

      <div className="signup-form-container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="signup-input-group">
            <label htmlFor="emailPrefix" className="email-label">Email Address  <span style={{ color: 'red' }}>*</span></label>
            <div className="email-input-container">
            <input
              type="text"
              id="emailPrefix"
              placeholder=" prefix of your email"
              {...register('emailPrefix')}
              className={`signup-input ${errors.emailPrefix ? 'input-error' : ''}`}
            />
            <span className="email-domain">@gmail.com</span>
            </div>
            {errors.emailPrefix && <span className="signup-error">{errors.emailPrefix.message}</span>}
          </div>

          <button type="submit" className="signup-button">
            Verify Email Address
          </button>

          <p className="help-center">
            For help, contact us through our <a href="/help-center" className="help-center-link">Help Center</a>.
          </p>
        </form>
      </div>

      {/* Footer */}
      <div style={{
                position: 'fixed',
                bottom: '0',
                left: '0',
                right: '0',
                textAlign: 'center',
                padding: '1rem 0',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 1
              }}>
          <img className="logofooter" src={postcarelogo} alt="Postcare Logo" 
            style={{ height: '30px', marginBottom: '0.5rem' }}/>
          <div style={{ fontSize: '0.875rem', display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
            <a href="/underdevelopment" style={{ color: '#2B60E6', textDecoration: 'none' }}>Terms of Use</a>
            <span>|</span>
            <a href="/underdevelopment" style={{ color: '#2B60E6', textDecoration: 'none' }}>Privacy Policy</a>
      </div>
    </div>
  </div>
  );
};

export default SignUp;

