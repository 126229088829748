import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import postcarelogo from '../assets/postcare.png';
import './css/signup.css';
import './css/popup.css'; // Importing popup styles
import axios from 'axios';

const WaitingPage = () => {
  const [tempPasswordInput, setTempPasswordInput] = useState('');
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { userEmail } = location.state || {};

  // Function to validate temporary password with backend
  const handleTempPasswordSubmit = async () => {
    const body= { email: userEmail, password: tempPasswordInput }
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASEURL}/auth/verify`, body, {
        withCredentials: true
      })
      console.log(response.status)
      if (response.status === 201) {
        // Show success pop-up
        setShowSuccessPopup(true);

        // After 1.5 seconds, navigate to set new password page
        setTimeout(() => {
          setShowSuccessPopup(false);
          navigate('/set-new-password');
        }, 2000);
      } else {
        alert('Invalid temporary password. Please try again.');
      }
    } catch (error) {
      console.error('Error during temporary password verification:', error);
      alert('An error occurred. Please try again.');
    }
  };

  // Redirect to login page after 8 minutes (480,000 milliseconds)
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/login');
    }, 480000);

    return () => clearTimeout(timer); // Clean up the timer on unmount
  }, [navigate]);
   const data=JSON.parse(JSON.stringify({ email: userEmail }))
  // Function to handle the resend verification email
  const resendVerificationEmail = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASEURL}/auth/sendlink`, data, {
        withCredentials: true
      })
      if (response.status===201) {
        alert('Verification email sent again to your email address.');
      } else {
        alert('Failed to resend verification email. Please try again.');
      }
    } catch (error) {
      console.error('Error during resend email verification:', error);
      alert('An error occurred. Please try again.');
    }
  };

  return (
    <div className="signup-container">
      <h2 className="signup-title">Verify your email to register</h2>

      <div className="verification-message">
        <p>You're almost there! We sent an email to <b>{userEmail}</b>.</p>
        <p>Just click on the link in that email to set up your password</p>
        <p>or</p>
        <p>Having trouble accessing the link? Enter the temporary password to proceed.</p>

        <div className="temp-password-input-group">
          <input
            type="text"
            id="password"
            value={tempPasswordInput}
            onChange={(e) => setTempPasswordInput(e.target.value)}
            placeholder="Enter code manually"
            className="signups-input"
            style={{ padding: '0.75rem', fontSize: '1rem', outline: 'None' , textAlign:'center', color:'gray'}}
          />
        </div>

        <button className="signup-button" style={{ width: '75%' }} onClick={handleTempPasswordSubmit}>
          Verify
        </button>

        <p>If you don't see the email, check your spam folder. Still can't find it? <button className="resend-button" onClick={resendVerificationEmail}>Resend Email</button></p>
      </div>

      {/* Footer */}
      <div style={{
                position: 'fixed',
                bottom: '0',
                left: '0',
                right: '0',
                textAlign: 'center',
                padding: '1rem 0',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 1
              }}>
          <img className="logofooter" src={postcarelogo} alt="Postcare Logo" 
            style={{ height: '30px', marginBottom: '0.5rem' }}/>
          <div style={{ fontSize: '0.875rem', display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
            <a href="/underdevelopment" style={{ color: '#2B60E6', textDecoration: 'none' }}>Terms of Use</a>
            <span>|</span>
            <a href="/underdevelopment" style={{ color: '#2B60E6', textDecoration: 'none' }}>Privacy Policy</a>
      </div>
    </div>

      {/* Success pop-up */}
      {showSuccessPopup && (
        <div className="popup-container">
          <div className="popup-content">
            <span className="checkmark">
              ✓
            </span>
            <p>Verified!</p>
          </div>
        </div>
      )}
    </div>
  );
};



export default WaitingPage;

