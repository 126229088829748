import React, { useState, useEffect } from "react";
import AICompanion from "./aicompanion";
import NavbarAIComp from "./NavAIcomp"; // Ensure correct import path
import Historypreview from "./historypreview";
import { FaStar } from "react-icons/fa"; // Import FaStar
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

const AIDashboard = () => {
  const [chatSessions, setChatSessions] = useState([]); // Array of chat sessions
  const [activeSession, setActiveSession] = useState(null); // Currently active chat
  const [resetAICompanion, setResetAICompanion] = useState(false); // Track reset for AICompanion
  const [isLogoutClicked, setIsLogoutClicked] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [currentValue, setCurrentValue] = useState(0);
  const [hoverValue, setHoverValue] = useState(undefined);
  const [editingSessionId, setEditingSessionId] = useState(null); // Track the session being edited
  const [newSessionName, setNewSessionName] = useState("");
  const [isThankYouVisible, setIsThankYouVisible] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [chatHistory, setChatHistory] = useState([]);
  const [selectedSessionId, setSelectedSessionId] = useState(null);
  const [initialChatName, setInitialChatName] = useState('');
  const [newChatActive, setNewChatActive] = useState(false); // New state for new chat
  const [isHistoryPreviewOpen, setIsHistoryPreviewOpen] = useState(false); // Track history preview
  const [messages, setMessages] = useState([]);
  const [showOptions, setShowOptions] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  // const [sessionRes, setSessionRes] = useState('');

  const { userEmail } = location.state || {};
  
  const showAlert = (message) => {
    alert(message); // Replace with a notification library if available
};

  const handleLogoutClick = () => {
    setIsLogoutClicked(true);
  };

  const handleLogout = async () => {
    try {
      localStorage.clear()
      await axios.get(`${process.env.REACT_APP_BASEURL}/auth/logout`, { withCredentials: true })
      navigate('/'); // Redirect to homepage
    } catch {
      navigate('/');
    }
  };

  const handleSubmitFeedback = () => {
    const feedbackData = {
      email: localStorage.getItem(userEmail), // Replace with actual email if needed
      stars: currentValue,
      comment: feedback,
    };

    // localStorage.setItem('feedback', JSON.stringify(feedbackData));
    console.log('Feedback saved:', feedbackData);

    // Handle feedback submission logic
    setIsThankYouVisible(true);
    setTimeout(() => {
      handleLogout(); // Trigger logout after thank you message
    }, 2000); // After 2 seconds, log out
  };

  useEffect(() => {
        const storedChatSessions = JSON.parse(localStorage.getItem('chatSessions')) || [];
        let initialSessionsSet = false;  //to not let empty chat get created after refresh
    if (storedChatSessions.length > 0) {
      setChatSessions(storedChatSessions);
      const lastActiveSessionId = localStorage.getItem('currentSession');
      const lastActiveSession = storedChatSessions.find(session => session.id === lastActiveSessionId);
      if (lastActiveSession) {
        setActiveSession(lastActiveSession);
        const initialChatHistory = JSON.parse(localStorage.getItem(`chat_${lastActiveSession.id}`)) || [];
        setChatHistory(initialChatHistory);
        console.log('Initial chatHistory on load:', initialChatHistory);  // Log on load
      } else {
        setActiveSession(storedChatSessions[0]); // Fallback to first session if last active session is not found
        const initialChatHistory = JSON.parse(localStorage.getItem(`chat_${storedChatSessions[0].id}`)) || [];
        setChatHistory(initialChatHistory);
        console.log('Fallback chatHistory on load:', initialChatHistory);  // Log on fallback
      }
      initialSessionsSet = true;
  } 
  
    const fetchChatSessions = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASEURL}/llm/chatsessions`, { withCredentials: true });
        const mapped = response.data.map(session => ({
          name: session.chatName,
          id: session.chatHistory
        }));
        const newMessages = response.data.filter(msg => msg.role !== 'system').map(msg => ({
          id: msg.chatHistory,
          name: msg.chatName,
        }));
        localStorage.setItem('chatSessions', JSON.stringify(newMessages));
        if (mapped.length > 0) {
        setChatSessions(mapped);
        setActiveSession(mapped[0])
        console.log('Fetched chat sessions:', mapped);  // Log fetched sessions
        initialSessionsSet= true
        }
      } catch (error) {
        console.error('Error fetching chat sessions:', error);
      }
      if (!initialSessionsSet) {
        handleNewChat();
      }
    };

    fetchChatSessions();
  }, []);

 

  // Load messages for a session from local storage
  const loadMessages = (sessionId) => {
    const storedMessages = localStorage.getItem(`chat_${sessionId}`);
    const parsedMessages = storedMessages ? JSON.parse(storedMessages) : [];
    console.log(`Loaded messages for chat_${sessionId}:`, parsedMessages);
    setChatHistory(parsedMessages);
    return parsedMessages;
  };

  useEffect(() => {
    if (activeSession && chatHistory.length > 0) {
      // Save to localStorage whenever chatHistory changes
      localStorage.setItem(`chat_${activeSession.id}`, JSON.stringify(chatHistory));
      // Update messages state
      setMessages(chatHistory)
    }
  }, [chatHistory, activeSession]);

  const handleNewChat = async () => {
    const newSessionId = `Chat ${chatSessions.length + 1}`; // Unique session identifier
    localStorage.setItem('temp',newSessionId)
    const newSession = {
      id: newSessionId,
      name: newSessionId,  // Ensure the session has a name
      timestamp: new Date().toISOString(), // Timestamp to track creation time
      messages: []
    };
    
    const updatedSessions = [...chatSessions, newSession];
    setChatSessions(updatedSessions); // Add new chat to sessions array
    // localStorage.setItem('chatSessions', JSON.stringify(updatedSessions));
    setActiveSession(newSession); // Set the new session as active
    // return
    // setResetAICompanion(true);
    setNewChatActive(true)
    setIsHistoryPreviewOpen(false)
    setChatHistory([]);
    setMessages([])

    localStorage.setItem(`chat_${newSessionId}`, JSON.stringify([])); // Initialize an empty chat history for the new session

  try {
    await axios.post(`${process.env.REACT_APP_BASEURL}/llm/chatsessions`, {
        id: newSessionId,
        name: newSessionId
    }, { withCredentials: true });
} catch (error) {
    console.error('Error saving new chat to database:', error);
}
};

  const handleSessionChange = (sessionId) => {
    const selectedSession = chatSessions.find((s) => s.id === sessionId);
    if (!selectedSession) {
      showAlert('Chat history could not be fetched. Please try again.');
      return;
    } 

    setActiveSession(selectedSession); // Switch to selected chat session
    setNewSessionName(sessionId);
    const savedChatHistory = loadMessages(sessionId);
    setChatHistory(savedChatHistory);
    setIsHistoryPreviewOpen(false);
  };

  useEffect(() => {
    const storedChatSessions = JSON.parse(localStorage.getItem('chatSessions')) || [];
    let initialSessionsSet = false;
    // Check if there are chat sessions stored in localStorage
    if (storedChatSessions.length > 0) {
      setChatSessions(storedChatSessions);
      setActiveSession(storedChatSessions[0]);
      initialSessionsSet = true;
    } 
  }, []);


  useEffect(() => {
    if (activeSession) {
      const sessionKey = `chat_${activeSession.id}`;
      const sessionHistory = JSON.parse(localStorage.getItem(sessionKey)) || [];
      setChatHistory(sessionHistory); // Update chat history with session's stored messages
    }
  }, [activeSession]);
  

  const handleNewChatMessage = (role, content, sessionId) => {
    // setChatHistory((prevHistory) => {
      const newMessage = {
          id: chatHistory.length + 1,
          message: content,
          type: role === 'assistant' ? 'bot' : 'user',
          timestamp: new Date()
        }
      const updatedHistory = [...chatHistory, newMessage];
      setChatHistory(updatedHistory)

      localStorage.setItem(`chat_${sessionId}`, JSON.stringify(updatedHistory));
      console.log('Updated chatHistory:', updatedHistory);
  };


  const handleHistoryPreview = async (sessionId) => {

    const savedChatHistory = localStorage.getItem('chatSessions');
    let selectedSession
if (savedChatHistory) {
  const chatSessions = JSON.parse(savedChatHistory);
  console.log("sessionId🤸🤸", sessionId);
  console.log("chatSessions🤸🤸", chatSessions);
  selectedSession = chatSessions.find((session) => session.chatName === sessionId);
  if(!selectedSession) {
    selectedSession = chatSessions.find((session) => session.id === sessionId);
  }
  console.log("selectedSession🤸🤸", selectedSession)
  setActiveSession(selectedSession);
}
    
    setSelectedSessionId(sessionId);
    console.log("sessionId", sessionId)
    setChatHistory([]);
    try { 
    const response = await axios.get(`${process.env.REACT_APP_BASEURL}/llm/chathistory?chatId=${selectedSession.id}`, { withCredentials: true });
    const newMessages = response.data.filter(msg => msg.role !== 'system').map(msg => ({
      id: msg._id,
      message: msg.content,
      type: msg.role === 'assistant' ? 'bot' : 'user',
      timestamp: new Date()
    }));

    console.log(`New messages from server for session ${sessionId}:`, newMessages);

    // localStorage.clear('currentS ession')
    localStorage.setItem('currentSession', sessionId)
    setChatHistory(newMessages);
    setIsHistoryPreviewOpen(true);
    setNewChatActive(true);
  } catch (error) {
    console.error(`Error fetching history for session ${sessionId}:`, error);
    alert("There was an error loading the chat history. Please try again later.");
  }
  };
  
  useEffect(() => {
    if (resetAICompanion) {
      setResetAICompanion(false); // Reset the flag to false after rendering
    }
  }, [resetAICompanion]);

  // Update local storage whenever chatHistory changes
  useEffect(() => {
    if (activeSession) {
      const storedMessages = localStorage.getItem(`chat_${activeSession.id}`);
      setChatHistory(storedMessages ? JSON.parse(storedMessages) : []);
    }
  }, [activeSession]);
  useEffect(() => {
    console.log('Updated chatHistory:', chatHistory);
  }, [chatHistory]);
  

  const addMessage = (newMessage) => {
    setChatHistory((prevHistory) => {
      const updatedHistory = [...prevHistory, newMessage];
      // Store the updated chat history in local storage for the active session
      localStorage.setItem(`chat_${activeSession.id}`, JSON.stringify(updatedHistory));
      console.log('Chat history after addMessage:', updatedHistory);  // Log after adding message
      return updatedHistory;
    });
  setIsHistoryPreviewOpen(true);
  setNewChatActive(true);
  };
  useEffect(() => {
    console.log('new chatHistory:', chatHistory);
  }, [chatHistory, activeSession]);

  // Function to enable editing mode
const handleEdit = (sessionId) => {
  setEditingSessionId(sessionId); // Set the session being edited
  setShowOptions(null);
  const session = chatSessions.find((s) => s.id === sessionId);
  if (session) {
    setNewSessionName(session.name); // Pre-fill the input with the current name
  }
};

// Function to handle renaming and sending the update to the server
const handleRename = async (sessionId) => {
  if (newSessionName.trim() !== "") {
    const updatedSessions = chatSessions.map((session) =>
      session.id === sessionId ? { ...session, name: newSessionName } : session
    );
    setChatSessions(updatedSessions); // Update session name in the history

    if (activeSession?.id === sessionId) {
      setInitialChatName(newSessionName); // Update the dynamic chat name
    }
    setEditingSessionId(null); // Stop editing after updating

    // Now, send the updated name to the server
    try {
      const authToken = localStorage.getItem('authToken'); // Retrieve the stored token

      await axios.patch(`${process.env.REACT_APP_BASEURL}/llm/chathistory?chatId=${sessionId}&name=${newSessionName}`, {}, {
        headers: { Authorization: `Bearer ${authToken}` },
        withCredentials: true,
      });
      console.log('Chat session name updated successfully');
    } catch (error) {
      if (error.response) {
        // Server responded with a status code outside the 2xx range
        switch (error.response.status) {
          case 401:
            alert('Unauthorized: Please log in again.');
            break;
          case 500:
            console.error('Server error: Please try again later.');
            break;
          default:
            console.error('An error occurred:', error.response.data.message || 'Please try again.');
        }
      } else if (error.request) {
        // Request was made, but no response was received
        console.error('No response received from the server. Please check your network connection.');
      } else {
        // Something happened while setting up the request
        console.error('Error setting up the request:', error.message);
      }
    }
  }
};


  const handleDelete = async (sessionId) => {
    const filteredSessions = chatSessions.filter((session) => session.id !== sessionId); // Remove chat session
    setChatSessions(filteredSessions); // Update session list
    if (activeSession && activeSession.id === sessionId) {
      setActiveSession(filteredSessions.length > 0 ? filteredSessions[0] : null); // Switch to the first session if deleted
    }
    try {
      await axios.delete(`${process.env.REACT_APP_BASEURL}/llm/chathistory?chatId=${sessionId}`, { withCredentials: true });
      console.log('Chat session deleted successfully');
    } catch (error) {
      console.error('Error deleting chat session:', error);
      if (error.response?.status === 401) {
        alert('Unauthorized: Please log in again.');
      }
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };



  // useEffect(() => {
  //   localStorage.setItem('chatSessions', JSON.stringify(chatSessions));
  // }, [chatSessions]);
 

  const updateChatSessionName = (sessionId, newName) => {
    console.log(`Updating chat session name for sessionId: ${sessionId} to newName: ${newName}`);
    console.log("current chat sessions", chatSessions);
  
    setChatSessions((prevSessions) => {
      console.log("prevSessions", prevSessions);
      const updatedSessions = prevSessions.map((session) =>
        session.id === sessionId ? { ...session, name: newName } : session
      );
      console.log('Updated chat sessions:', updatedSessions);
      return updatedSessions; // Ensure the updated sessions are returned
    });
  
    // Update localStorage as well
    const sess = localStorage.getItem("chatSessions");
    const sessions = JSON.parse(sess);
    const updatedLocalStorageSessions = sessions.map((session) =>
      session.id === sessionId ? { ...session, name: newName } : session
    );
    localStorage.setItem("chatSessions", JSON.stringify(updatedLocalStorageSessions));
    console.log('Updated chat sessions in localStorage:', updatedLocalStorageSessions);
  };

 
  return (
    <div>
      <NavbarAIComp
        chatSessions={chatSessions}
        onSessionChange={handleSessionChange}
        handleEdit={handleEdit}
        editingSessionId={editingSessionId} // Pass the editing session state
        newSessionName={newSessionName} // Pass the new session name
        setNewSessionName={setNewSessionName} // Function to set new session name
        handleRename={handleRename}
        handleDelete={handleDelete}
        onNewChat={handleNewChat}
        toggleSidebar={toggleSidebar}
        isSidebarOpen={isSidebarOpen}
        onLogoutClick={handleLogoutClick}
        activeSession={activeSession}
        // chatName={initialChatName}
        onHistoryPreview={handleHistoryPreview}
       chatName={activeSession?.name}
        messages={messages}
        handleNewChatMessage={handleNewChatMessage}
      />

      {isHistoryPreviewOpen && activeSession ? (
        <Historypreview
          sessionId={selectedSessionId}
          isSidebarOpen={isSidebarOpen}
          messages={messages}
          session={activeSession}
          chatHistorys={chatHistory}
          activeSession={activeSession}
          handleNewChatMessage={handleNewChatMessage}
          addMessage={addMessage}

        />
      ) : (
      activeSession  && (
          <AICompanion
            isSidebarOpen={isSidebarOpen}
            setInitialChatName={setInitialChatName} 
            key={activeSession.id}
            sessionId={activeSession.id}
            messages={chatHistory}
            chatHistory={chatHistory}
            addMessage={addMessage}
            activeSession={activeSession}
            updateChatSessionName={updateChatSessionName}
          />
        )
      )}

      {isLogoutClicked && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50" style={{ zIndex: '999' }}>
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-lg font-semibold mb-4">Thank you for using Postcare's AI Companion!</h2>
            <div className="flex mb-4">
              {Array(5).fill(0).map((_, index) => (
                <FaStar
                  key={index}
                  size={24}
                  onClick={() => setCurrentValue(index + 1)}
                  onMouseOver={() => setHoverValue(index + 1)}
                  onMouseLeave={() => setHoverValue(undefined)}
                  color={(hoverValue > index || currentValue > index) ? '#FFD700' : '#C0C0C0'}
                  style={{ marginRight: 10, cursor: 'pointer', transition: 'color 0.3s ease' }}
                />
              ))}
            </div>
            <textarea
              className="w-full p-2 border border-gray-300 rounded mb-4"
              rows="4"
              placeholder="Optional feedback"
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
            ></textarea>
            {isThankYouVisible && <p className="text-green-500 mb-4">Thank you for your valuable feedback!</p>}

            <div className="flex justify-end space-x-4">
              <button
                onClick={handleSubmitFeedback}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
              >
                Submit
              </button>
              <button
                onClick={() => setIsLogoutClicked(false)}
                className="text-gray-500 px-4 py-2 rounded hover:text-gray-700"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};



export default AIDashboard;