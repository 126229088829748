import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const token = localStorage.getItem("accessToken");
  // const accessToken = document.cookie
  //   .split('; ')
  //   .find(row => row.startsWith('access_token='))
  //   ?.split('=')[1];


    // Only protect the /ai-companion route by redirecting to /login if access token is missing
    if (!token) {
      alert("Please log in to access this page.");
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  
    return children;
  };
  

export default ProtectedRoute;
