import React, { useState, useEffect, useRef } from 'react';
import { FaCircleArrowUp } from "react-icons/fa6";
// import { generateMessage, generateMessagesArray } from "./utils";
import './historysection.css';
import { MdCancel } from "react-icons/md";
import { FaPills, FaDiagnoses, FaHeartbeat } from 'react-icons/fa';
import ResponseFormat from './responseformat';
import axios from 'axios';
import iicon from '../../assets/iicon.png';
import { v4 as uuid } from "uuid";
import io from 'socket.io-client';
import ReactScrollableFeed from 'react-scrollable-feed';


const AICompanion = ({ isSidebarOpen, chatHistory: initialChatHistory, addMessage, messages, activeSession, updateChatSessionName }) => {
  const [conversations, setConversations] = useState([]);
  const [currentConversations, setCurrentConversations] = useState([]);
  const [initialChatName, setInitialChatName] = useState('');
  const [newChatName, setNewChatName] = useState('');
  const [activeChat, setActiveChat] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [feedbacks, setFeedbacks] = useState([]);
  const [showOptions, setShowOptions] = useState(true);
  const [timer, setTimer] = useState(1800);  // 30 minutes in seconds
  const [hasQueried, setHasQueried] = useState(false);
  const [currentResponse, setCurrentResponse] = useState(''); // Buffer for response tokens
  const [messageBuffer, setMessageBuffer] = useState('');
  const [sessionExpired, setSessionExpired] = useState(false);
  const [clientId, setClientId] = useState(() => uuid());
  const [isNavbarShrunk, setIsNavbarShrunk] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [chatBodyHeight, setChatBodyHeight] = useState(0);
  const [sessionRes, setSessionRes] = useState('');
  const [task, setTask] = useState('Medication');
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [isNotificationVisible, setIsNotificationVisible] = useState(true); // State to control visibility
  const { userEmail } = Location.state || {};
  const [chatSessions, setChatSessions] = useState(() => {
    const sessions = localStorage.getItem('chatSessions');
    return sessions ? JSON.parse(sessions) : [];
  });
  const username = userEmail?.split('@')[0] || 'User';

  const chatBodyRef = useRef(null);
  const textareaRef = useRef(null);
  const topBarRef = useRef(null);
  const chatInputRef = useRef(null);
  const lastMessageRef = useRef(null);
  const socketRef = useRef(null); // Define socketRef using useRef



  useEffect(() => {
    // Example condition when navbar shrinks (adjust as per your logic)
    const handleResize = () => {
      requestAnimationFrame(() => {
        setScreenWidth(window.innerWidth);
        if (window.innerWidth < 1024) {
          setIsNavbarShrunk(true);
        } else {
          setIsNavbarShrunk(false);
        }

        const topbarHeight = document.querySelector('.top-bars-container').offsetHeight;
        const inputHeight = document.querySelector('.chat-input-container').offsetHeight;
        const scrollbodyHeight = window.innerHeight - (topbarHeight + inputHeight);
        setChatBodyHeight(scrollbodyHeight);

        // const chatBody = document.querySelector('.chat-body');
        if (chatBodyRef.current) {
          chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
        }
      });

    };

    window.addEventListener('resize', handleResize);

    // Call the handleResize function on component mount to apply initial maxHeight
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [topBarRef, chatInputRef, chatHistory, currentResponse, chatBodyHeight.length]);

  useEffect(() => {
    // Function to calculate available space for chat-body
    const calculateChatBodyHeight = () => {
      const topBarHeight = topBarRef.current?.offsetHeight || 0;
      const inputHeight = chatInputRef.current?.offsetHeight || 0;
      const availableHeight = window.innerHeight - inputHeight;
      setChatBodyHeight(availableHeight);
    };

    // Initial calculation and on window resize
    calculateChatBodyHeight();
    window.addEventListener('resize', calculateChatBodyHeight);

    // Cleanup
    return () => window.removeEventListener('resize', calculateChatBodyHeight);
  }, [topBarRef,]);

  useEffect(() => {
    // socketRef.current = io('ws://localhost:5000',{
    //   transports: ['websocket','polling'],
    // })
    socketRef.current = io(process.env.REACT_APP_SOCKET_URL, {
      transports: ['websocket', 'polling'],
    })

    socketRef.current.on('connect', () => {
      console.log('WebSocket connected');
      socketRef.current.emit('register', { clientId });
    });

    socketRef.current.on('response', (message) => {
      // console.log(message)
      if (message.id === clientId) {
        const words = message.response.split(' ');

        words.forEach((word, index) => {
          setTimeout(() => {
            setMessageBuffer(prevBuffer => {
              const updatedBuffer = prevBuffer + ' ' + word;

              if (updatedBuffer.includes('<|eot_id|>')) {
                const [fullMessage, ...remainingBuffer] = updatedBuffer.split('<|eot_id|>');
                const trimmedMessage = fullMessage.trim();

                setChatHistory(prevHistory => [
                  ...prevHistory,
                  {
                    id: prevHistory.length + 1,
                    message: trimmedMessage,
                    type: 'bot',
                    timestamp: new Date()
                  }
                ]);
                setCurrentResponse('');
                return remainingBuffer.join('<|eot_id|>');
              }

              setCurrentResponse(prevResponse => prevResponse + ' ' + word);

              return updatedBuffer;
            });
          }, index * 5);
        });
      }
    });

    socketRef.current.on('disconnect', () => {
      console.log('WebSocket disconnected');
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, [clientId]);


  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(interval);
          setSessionExpired(true);
        }
        return prevTimer - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

 


  const scrollToBottom = () => {
    // const chatBody = document.getElementById("chatBody"); // Ensure this ID is correct
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory, feedbacks, currentResponse, messages, initialChatHistory]);

  // it should not send request
  const startNewChat = async (initialMessage = '') => {
    try {
      // return
      // const response = await axios.post(`${process.env.REACT_APP_BASEURL}/llm/start-session`, { initialMessage }, { withCredentials: true });
      const newSession = {
        // id: response.data.chatId, // Use the chatId from the response
        name: initialMessage.substring(0, 20) + (initialMessage.length > 20 ? '...' : ''),
        history: [],
        subheading: initialMessage.substring(0, 20)
      };
      setConversations(prevConversations => [...prevConversations, newSession]);
      setActiveChat(newSession.id);
      setInitialChatName(newSession.name);
    } catch (error) {
      console.error('Error starting new chat session:', error);
    }
  };


  const hardcodedResponses = [
    "This is a hardcoded bot response.",
    `Hi ${username}, I'm here to help you with all your queries regarding medication!`,
    `Hi ${username}, let's discuss your diagnosis. What would you like to know?`,
    `Hi ${username}, I'm here to guide you on lifestyle choices for better health!`,
    `Hi ${username}, how else can I assist you today?`
  ];



  const handleOptionClick = async (option) => {
    setShowOptions(false);
    setActiveChat(null);

    let botResponse = '';

    switch (option) {
      case 'Medication':
        botResponse = `Hi ${username}, I'm here to help you with all your queries regarding medication!`;
        break;
      case 'Diagnosis':
        botResponse = `Hi ${username}, let's discuss your diagnosis. What would you like to know?`;
        break;
      case 'Lifestyle':
        botResponse = `Hi ${username}, I'm here to guide you on lifestyle choices for better health!`;
        break;
      case 'Others':
        botResponse = `Hi ${username}, how else can I assist you today?`;
        break;
      default:
        botResponse = "This is a hardcoded bot response.";
    }

    const responseMessage = {
      id: chatHistory.length + 1,
      message: botResponse,
      type: 'bot',
      timestamp: new Date()
    };


    if (!activeChat) {
      startNewChat(option);
      setChatHistory([responseMessage]);
    } else {
      const updatedConversations = conversations.map(chat => {
        if (chat.id === activeChat) {
          return { ...chat, history: [...chat.history, responseMessage], subheading: option.substring(0, 20) };
        }
        return chat;
      });
      setConversations(updatedConversations);
      setChatHistory(prevHistory => [...prevHistory, responseMessage]);
    }
    console.log(chatHistory)

    try {
      const payload = { "selectedOption": option, "id": clientId, "chatName": "New chat" };
      const response = await axios.post(`${process.env.REACT_APP_BASEURL}/llm/start-session`, payload,
        { withCredentials: true });
      setTask(option)
      setSessionRes(response);
      const newSessionId = `Chat ${chatSessions.length + 1}`;
      const newSession = {
        id: response.data.chatId,
        chatName: newSessionId,  // Ensure the session has a name
        timestamp: new Date().toISOString(), // Timestamp to track creation time
        messages: []
      };
      const updatedSessions = [...chatSessions, newSession];
      setChatSessions(updatedSessions); // Add new chat to sessions array
      localStorage.setItem('chatSessions', JSON.stringify(updatedSessions));
    } catch (error) {
      console.error('Error starting LLM Model:', error);
    }
  };

  const sendMessageToBot = async (message) => {
    const userMessage = {
      id: chatHistory.length + 1,
      message,
      type: 'user',
      timestamp: new Date()
    };

    console.log('chatHistory before updating:', chatHistory);

    const updatedHistory = [...chatHistory, userMessage];
    setChatHistory(updatedHistory);
    setHasQueried(true);

    console.log('History after sending msg:', updatedHistory);

    try {
      if (!activeChat) {
        startNewChat(message);
      } else {
        const updatedConversations = conversations.map(chat => {
          if (chat.id === activeChat) {
            return { ...chat, history: updatedHistory, subheading: message.substring(0, 20) };
          }
          return chat;
        });
        setConversations(updatedConversations);
      }
    } catch (error) {
      console.log("bruuh", error);
    }

    // Send the message through the WebSocket
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(JSON.stringify({ query: message, id: clientId, chatId: sessionRes.data.chatId }));
    }

    // Call the get-response API to trigger the backend logic
    try {
      if (sessionRes && sessionRes.data && sessionRes.data.chatId) {
        const response = await axios.post(`${process.env.REACT_APP_BASEURL}/llm/get-response`, { query: message, id: clientId, chatId: sessionRes.data.chatId, option: task }, { withCredentials: true });
        console.log('API response:', response);

      
        const newName = message.substring(0, 20) + (message.length > 20 ? '...' : '');
        await axios.patch(`${process.env.REACT_APP_BASEURL}/llm/chathistory`, { name: newName, chatId: sessionRes.data.chatId }, { withCredentials: true });
      console.log("just for record",sessionRes.data.chatId)
        setChatSessions((prevSessions) =>
          prevSessions.map((session) =>
            session.id === activeChat ? { ...session, id: sessionRes.data.chatId } : session
          )
        );
        updateChatSessionName(sessionRes.data.chatId, newName);
      
      
        const updatedConversations = conversations.map(chat => {
          if (chat.id === activeChat) {
            return { ...chat, name: newName };
          }
          return chat;
        });
        setConversations(updatedConversations);
        setInitialChatName(newName); // Update the initial chat name state
       // Update the initial chat name state
      } else {
        console.error('sessionRes or sessionRes.data.chatId is undefined');
      }
    } catch (error) {
      console.error('Error querying LLM model:', error);
    }
  };



  const handleSendMessage = () => {
    if (newChatName.trim() === '') return;
    setInitialChatName(async (prev) => {
      if (!prev) { // Only update if it's the first message
        const trimmedMessage = newChatName.trim();
        const newName = trimmedMessage.substring(0, 20) + (trimmedMessage.length > 20 ? '...' : '');
        await axios.patch(`${process.env.REACT_APP_BASEURL}/llm/chathistory`, { name: newName, chatId: sessionRes.data.chatId }, { withCredentials: true });
        return newName;
      }
      return prev; // If a name already exists, don't update
    });

    sendMessageToBot(newChatName);
    setNewChatName('');
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Prevent default Enter behavior
      handleSendMessage();
    }
  };

  const handleFeedback = (feedback) => {
    setFeedbacks([...feedbacks, feedback]);
  };

  const marginLeftValue = screenWidth >= 1200 ? (isSidebarOpen ? '255px' : '45px') : '';


  useEffect(() => {
    const chatBody = chatBodyRef.current;
    if (chatBody) {
      const handleScroll = () => {
        const isScrolledToBottom =
          chatBody.scrollHeight - chatBody.scrollTop === chatBody.clientHeight;
        setShowScrollButton(!isScrolledToBottom);
      };
      chatBody.addEventListener('scroll', handleScroll);
      return () => chatBody.removeEventListener('scroll', handleScroll);
    }
  }, []);


  return (
    <div className="ai-companion-container" style={{ marginLeft: marginLeftValue }} >
      <div className={`bg-main ${isSidebarOpen ? 'sidebar-open' : 'sidebar-collapsed'}`} style={{ backgroundColor: '#F8F7F7' }}>
        <div className="top-bars-container" style={{ backgroundColor: 'rgb(248, 247, 247)', padding: '0px 0px 5px' }}>
          {isNotificationVisible && (
            <div className="notification-bar">
              <img src={iicon} alt="ibutton" className="icon-info-circle" ></img>
              <div className="notification-content">
                <span className="notification-text" >You are on a free trial, each session is valid for 30 mins. Your current session will end in -</span>
                <span className="timer-text" style={{ color: timer <= 300 ? 'red' : 'black' }}>{formatTime(timer)} minutes</span>
              </div>
              <button className="cancel-btn" style={{ marginLeft: '14px' }} onClick={() => setIsNotificationVisible(false)} ><MdCancel /></button>
            </div>
          )}
          <div className="care-bar-container" style={{ marginTop: isNotificationVisible ? '0%' : '3%' }}>
          </div>
          <div className="care-bar">
            <h1 className='Slogan' >Always There, Always Care.....</h1>
            <p className='Slogan_descp' > Your personal AI Companion available 24/7 to answer your queries</p>
          </div>
        </div>
        <div className="chat-content-wrapper" ref={chatBodyRef}>
          <div className="chat-header" >
            {showOptions && (
              <div className="header-text" style={{ color: '#5B62FD' }}>
                <h1>Hello there! Welcome to PostCare.AI</h1>
                <p>How may I assist you today?</p>
              </div>
            )}
            {showOptions && (
              <div className="options-blocks">
                <div className="option-block" onClick={() => handleOptionClick('Medication')}>
                  <div className="option-content">
                    <FaPills className="option-icon" />
                    <div className="option-text">
                      <span className="option-name">Medication</span>
                      <div className="option-description">Ask me about your prescribed medications or old prescriptions</div>
                    </div>
                  </div>
                </div>

                <div className="option-block" onClick={() => handleOptionClick('Diagnosis')}>
                  <div className="option-content">
                    <FaDiagnoses className="option-icon" />
                    <div className="option-text">
                      <span className="option-name">Diagnosis</span>
                      <div className="option-description">Ask me about your action plan or diagnosed condition related</div>
                    </div>
                  </div>
                </div>

                <div className="option-block" onClick={() => handleOptionClick('Lifestyle')}>
                  <div className="option-content">
                    <FaHeartbeat className="option-icon" />
                    <div className="option-text">
                      <span className="option-name">Lifestyle</span>
                      <div className="option-description">Ask me about healthy lifestyle, exercise or customized diet plan</div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="chat-body" ref={chatBodyRef} style={{ overflowY: 'hidden', maxHeight: `${chatBodyHeight}px` }}>
            <ReactScrollableFeed >
              {chatHistory.map((message, index) => (
                <ResponseFormat
                  key={message.id}
                  message={message}
                  // ref={index === chatHistory.length - 1 ? lastMessageRef : null}
                  onFeedback={handleFeedback}
                  scrollToBottom={scrollToBottom}
                  type={message.role === 'assistant' ? 'bot' : 'user'}
                  timestamp={message.timestamp}
                  hideFeedback={hardcodedResponses.includes(message.message)} // Check if the response is a hardcoded one
                />
              ))}
              {currentResponse && (
                <ResponseFormat
                  key={chatHistory.length}
                  message={{ id: chatHistory.length + 1, message: currentResponse.trim(), type: 'bot', timestamp: new Date() }}
                  onFeedback={handleFeedback}
                  scrollToBottom={scrollToBottom}
                  type="bot"
                  timestamp={new Date()}
                  hideFeedback={hardcodedResponses.includes(currentResponse.trim())} // Check if the response is a hardcoded one
                />
              )}
            </ReactScrollableFeed>
          </div>
        </div>
        <div className="chat-input-container" ref={chatInputRef} style={{ marginLeft: screenWidth >= 1200 ? (isSidebarOpen ? '5%' : '15%') : '' }} >
          <div
            className="chat-input-section"
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '10px',
              position: 'relative',
            }}
          >
            <div style={{ flexGrow: 1, position: 'relative' }}>
              <textarea
                ref={textareaRef}
                value={newChatName}
                onChange={(e) => setNewChatName(e.target.value)}
                className="chat-input"
                placeholder="Type Here..."
                onKeyDown={handleKeyPress}
                style={{
                  width: '100%',
                  padding: '10px',
                  borderRadius: '20px',
                  outline: 'none',
                  fontSize: '15px',
                  resize: 'none', // Disable manual resizing by the user
                  overflowY: 'auto', // Enable scrolling when content exceeds max height
                  maxHeight: '80px', // Max height for 3 lines (approximately 80px for 3 lines)
                  paddingRight: '40px', // Add padding to avoid overlap with the send button
                  boxSizing: 'border-box', // Ensure padding is included in the size calculation
                  height: 'auto', // Dynamically adjust height based on content
                }}
                rows={1} // Start with 1 row by default
                onInput={(e) => {
                  // Dynamically adjust the height based on content
                  e.target.style.height = 'auto'; // Reset height
                  e.target.style.height = `${Math.min(e.target.scrollHeight, 80)}px`; // Restrict max height to 80px (~3 lines)
                }}
              />
            </div>
            <FaCircleArrowUp
              style={{
                position: 'absolute',
                right: '28px',
                color: 'black',
                cursor: 'pointer',
                fontSize: '24px',
              }}
              onClick={handleSendMessage}
            />
          </div>
          {/* <button
      className="send-button"
      onClick={handleSendMessage}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'black',
        color: 'white',
        border: 'none',
        borderRadius: '50%',
        width: '40px',
        height: '40px',
      }}
    >
      <FaCircleArrowUp />
    </button>
  </div> */}

          {/* Centered message below the input section */}
          <div
            className="experimental-message"
          // style={{
          //   textAlign: 'center',
          //   // marginTop: '10px',
          //   fontSize: '9px',
          //   color: '#666',
          // }}
          >
            This is an experimental conversational AI. It can make mistakes.
          </div>
        </div>

      </div>
    </div>
  );
};

export default AICompanion;