import React from 'react';
import { useNavigate } from 'react-router-dom';
import './welcomepage.css';
import welcomeimg from '../../assets/welcomepng.png';
import postcarelogo from '../../assets/postcare.png';

const PatientWelcomePage = () => {
    const navigate = useNavigate();

    return (
      <div className="welcome-page-container">
        {/* Left Section */}
        <div className="left-section">
          <div className="logo">
            <img src={postcarelogo} alt="logo" />
          </div>
          <div className="welcome-content">
            <img src={welcomeimg} alt="Welcome" className="welcome-image" />
            <p className="welcome-text">
              Your AI Companion proactively ready to answer your medical queries.
            </p>
          </div>
        </div>

        {/* Right Section */}
        <div className="right-section" style={{ 
              position: 'relative', 
              minHeight: '100vh', 
              overflow: 'hidden', 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center', 
              justifyContent: 'center', 
              paddingBottom: '3rem' // added space for bottom links
            }}>
          <h3>Welcome</h3>
          <p>Log in with your PostCare account to continue</p>
          <div className="button-group">
            <button
              className="btn"
              onClick={() => navigate('/login')}
            >
              Log in
            </button>
            <button
              className="btn secondary"
              onClick={() => navigate('/signup')}
            >
              Sign up
            </button>
          </div>
          {/* /* Terms and Privacy Policy Links */} 
          <div style={{
            position: 'absolute',
            bottom: '1rem',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            gap: '10px',
            fontSize: '0.875rem',
            overflow: 'hidden' // prevent overflow of links
          }}>
            <a href="/underdevelopment" style={{ textDecoration: 'none', color: 'white' }}>Terms of Use</a>
            <span>|</span>
            <a href="/underdevelopment" style={{ textDecoration: 'none', color: 'white' }}>Privacy Policy</a>
          </div>
        </div>
      </div>
    );
};

export default PatientWelcomePage;








// **********************************

// import React, { useState } from 'react';
// import { useForm } from 'react-hook-form';
// import { zodResolver } from '@hookform/resolvers/zod';
// import * as z from 'zod';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios'; // Import axios
// import './welcomepage.css';
// import welcomeimg from '../../assets/welcomepng.png';
// import postcarelogo from '../../assets/postcare.png';

// // Zod schema for signup
// const signUpSchema = z.object({
//   name: z.string().min(1, 'Name is required'),
//   emailOrPhone: z.string().min(1, 'Email or Phone is required'),
//   dob: z.string().nonempty('Date of Birth is required'),
//   password: z.string().min(6, 'Password must be at least 6 characters long'),
// });

// // Zod schema for login
// const loginSchema = z.object({
//   email: z.string().min(1, 'Email is required'),
//   password: z.string().min(6, 'Password must be at least 6 characters long'),
// });

// const WelcomePage = () => {
//   const [showForm, setShowForm] = useState(null); // State to manage which form is shown
//   const navigate = useNavigate(); // Hook for navigation

//   const handleSuccess = () => {
//     navigate('/ai-companion'); // Navigate to /ai-companion on success
//   };

//   const SignUpForm = () => {
//     const { register, handleSubmit, formState: { errors } } = useForm({
//       resolver: zodResolver(loginSchema),
//     });

//     const onSubmit = async data => {
//         const dob = `${data.day}/${data.month}/${data.year}`;
//         const formData = { ...data, dob };
        
//       try {
//         const response = await axios.post('http://localhost:5000/auth/register', data);
//         if (response.data.message === 'Signed in successfully') {
//           handleSuccess(); // Navigate on success
//         } else {
//           alert('Register failed. Please try again.');
//         }
//       } catch (error) {
//         alert('An error occurred during register. Please try again.');
//       }
//     };

//     return (
//       <div style={{ backgroundColor: 'white', borderRadius: '15px', padding: '2rem', marginTop: '20px', width: '350px' }}>
//         <h3>Register</h3>
//         <form onSubmit={handleSubmit(onSubmit)}>
//           <div style={{ marginBottom: '10px' }}>
//             <input
//               type="text"
//               placeholder="Name"
//               {...register('name')}
//               style={{ width: '100%', padding: '0.5rem', borderRadius: '5px', border: '1px solid lightgrey', color: 'darkgrey' }}
//             />
//             {errors.name && <p style={{ color: 'red' }}>{errors.name.message}</p>}
//           </div>
//           <div style={{ marginBottom: '10px' }}>
//             <input
//               type="text"
//               placeholder="Email or Phone"
//               {...register('emailOrPhone')}
//               style={{ width: '100%', padding: '0.5rem', borderRadius: '5px', border: '1px solid lightgrey', color: 'darkgrey' }}
//             />
//             {errors.emailOrPhone && <p style={{ color: 'red' }}>{errors.emailOrPhone.message}</p>}
//           </div>
//           <div style={{ marginBottom: '10px', display: 'flex', gap: '5px'}}>
//             <select
//               {...register('day')}
//               style={{ width: '30%', padding: '0.5rem', borderRadius: '5px', border: '1px solid lightgrey', color: 'darkgrey' }}
//             >
//               <option value="" >Day</option>
//               {Array.from({ length: 31 }, (_, i) => (
//               <option key={i + 1} value={i + 1}>{i + 1}</option>
//             ))}
//           </select>
//           <select {...register('month')} style={{ width: '35%', padding: '0.5rem', borderRadius: '5px', border: '1px solid lightgrey', color: 'darkgrey' }}>
//             <option value="">Month</option>
//             {/* Add month options */}
//             {Array.from({ length: 12 }, (_, i) => (
//               <option key={i + 1} value={i + 1}>{i + 1}</option>
//             ))}
//           </select>
//           <select {...register('year')} style={{ width: '35%', padding: '0.5rem', borderRadius: '5px', border: '1px solid lightgrey', color: 'darkgrey' }}>
//             <option value="">Year</option>
//             {/* Add year options */}
//             {Array.from({ length: 100 }, (_, i) => {
//               const year = new Date().getFullYear() - i;
//               return <option key={year} value={year}>{year}</option>;
//             })}
//           </select>
//         </div>
//             {errors.dob && <p style={{ color: 'red' }}>{errors.dob.message}</p>}
//           <div style={{ marginBottom: '10px' }}>
//             <input
//               type="password"
//               placeholder="Password"
//               {...register('password')}
//               style={{ width: '100%', padding: '0.5rem', borderRadius: '5px', border: '1px solid lightgrey', color: 'darkgrey' }}
//             />
//             {errors.password && <p style={{ color: 'red' }}>{errors.password.message}</p>}
//           </div>
//           <button style={{
//             backgroundColor: '#4877EA',
//             color: 'white',
//             borderRadius: '25px',
//             padding: '10px 20px',
//             fontSize: '16px',
//             fontWeight: '500',
//             border: 'none',
//             cursor: 'pointer',
//             width: '100%',
//             marginTop: '10px'
//           }}>Register</button>
//         </form>
//         <p style={{ marginTop: '10px', fontSize:'14px', color:'gray', marginLeft:'20px'}}>
//           Already have an account? <span onClick={() => setShowForm('login')} style={{ color: '#4877EA', cursor: 'pointer' }}>Log in</span>
//         </p>
//       </div>
//     );
//   };

//   const LoginForm = () => {
//     const { register, handleSubmit, formState: { errors } } = useForm({
//       resolver: zodResolver(signUpSchema),
//     });

//     const onSubmit = async data => {
//       try {
//         const response = await axios.post('http://localhost:5000/auth/login', data);
//         if (response.data.message === 'Logged in successfully') {
//           handleSuccess(); // Navigate on success
//         } else {
//           alert('Login in failed. Please try again.');
//         }
//       } catch (error) {
//         alert('An error occurred during logging in. Please try again.');
//       }
//     };

//     return (
//       <div style={{ backgroundColor: 'white', borderRadius: '15px', padding: '2rem', marginTop: '20px', width: '350px' }}>
//         <h3>Sign Up Form</h3>
//         <form onSubmit={handleSubmit(onSubmit)}>
//           <div style={{ marginBottom: '10px' }}>
//             <input
//               type="text"
//               placeholder="Username or Email"
//               {...register('username')}
//               style={{ width: '100%', padding: '0.5rem', borderRadius: '5px', border: '1px solid lightgrey', color: 'darkgrey' }}
//             />
//             {errors.username && <p style={{ color: 'red' }}>{errors.username.message}</p>}
//           </div>
//           <div style={{ marginBottom: '10px' }}>
//             <input
//               type="password"
//               placeholder="Password"
//               {...register('password')}
//               style={{ width: '100%', padding: '0.5rem', borderRadius: '5px', border: '1px solid lightgrey', color: 'darkgrey' }}
//             />
//             {errors.password && <p style={{ color: 'red' }}>{errors.password.message}</p>}
//           </div>
//           <button style={{
//             backgroundColor: '#4877EA',
//             color: 'white',
//             borderRadius: '25px',
//             padding: '10px 20px',
//             fontSize: '16px',
//             fontWeight: '500',
//             border: 'none',
//             cursor: 'pointer',
//             width: '100%',
//             marginTop: '10px'
//           }}>Log in</button>
//         </form>
//         <p style={{ marginTop: '10px', color:'gray',fontSize:'15px' }}>
//           Create a new account? <span onClick={() => setShowForm('signup')} style={{ color: '#4877EA', cursor: 'pointer' }}>Sign up</span>
//         </p>
//       </div>
//     );
//   };

//   return (
//     <div style={{ display: 'flex', background: 'white', height: '100vh', backgroundColor: '#f0f0f0' }}>
//       <div className="left-section">
//         <div className='logotop' style={{ height: '60px', width: '270px', marginTop: '-20px', marginLeft: '-620px' }}>
//           <img src={postcarelogo} alt='logo' />
//         </div>
//         <div style={{ flex: 1, marginLeft: '-10px' }}>
//           <img src={welcomeimg} alt="Welcome" style={{ marginTop: '10px' }} />
//           <p style={{ fontWeight: 'bold', color: '#4f4b4b', width: '580px', marginLeft:'20px' }}>Your AI Companion proactively ready to answer your medical queries.</p>
//         </div>
//       </div>
//       <div style={{ flex: '3', background: '#4877EA', padding: '2rem', color: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
//         <h3 style={{ fontSize: '38px', fontWeight: '600', marginTop: '-10px' }}>Welcome Back!</h3>
//         <p style={{ fontSize: '18px', color: '#D3D3D3', marginTop: '-0px' }}>Enter your details to access your account</p>
//         {showForm === 'signup' ? <SignUpForm /> : <LoginForm />}
//       </div>
//     </div>
//   );
// };

// export default WelcomePage;

