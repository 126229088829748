import React, {useState}from 'react';
import { BrowserRouter as Router, Routes, Route,  } from 'react-router-dom';
// import Navbar from './components/Navbar';
// import Dashboard from './pages/dashboard/dashboard';
// import Activity from './pages/activity/activity';
// import Message from './pages/message/message';
// import Appointment from './pages/appointment/appointment';
// // import Assessment_forms from './pages/assessment_forms/assessment_form';
// import Referrals from './pages/referrals/referrals';
// import Reports from './pages/reports/reports';
// import MedicalHistory from './pages/med_history/med_history';
// import Header from './components/header';
import ProtectedRoute from './components/protectedroute.js';
import Login from './components/Login';
import SignUp from './components/Signup';
import ResetNewPassword from './components/reset_password.js';
import SetNewPassword from './components/forgot_password';
import WaitingPage from './components/waitingPage.js';
import AIDashboard from './pages/ai_companion/aidashboard.js';
import UnderDevelopmentPage from './components/underdevelopment.js';
import PatientWelcomePage from './pages/WelcomePage/patientswelcompage.js';
import HomePage from './pages/WelcomePage/homepage.js';
import ProviderWelcomePage from './pages/WelcomePage/providerswelcomepage.js';
import TempLogin
 from './components/TempLogin.js';
const App = () => {
  const [, ] = useState(false);

  return (
    <Router>
      <div className="flex">
        {/* <Navbar /> */}
        {/* <Header toggleBlur={toggleBlur} isBlurred={isBlurred}/>
        <div className={`content ${isBlurred ? 'blur-background' : ''}`}> */}
        <div className="flex-grow p-0" >
          {/* <Login/> */}
        <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/patientsdashboard" element={<PatientWelcomePage />} />
        <Route path="/providersdashboard" element={<ProviderWelcomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup-verify" element={<WaitingPage />} />
        <Route path="/reset-password" element={<ResetNewPassword />} />
        <Route path="/set-new-password" element={<SetNewPassword />} />
        <Route path="/reset-altverify" element={<TempLogin />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/ai-companion" element={<ProtectedRoute> <AIDashboard /> </ProtectedRoute>} />
        <Route path="/underdevelopment" element={<UnderDevelopmentPage />} />

        </Routes>
        {/* <AIBot/> */}
            {/* <Route path="/ai_bot" element={<AIBot />} /> */}
            {/* <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/message" element={<Message />} />
            <Route path="/appointment" element={<Appointment />} />
            {/* <Route path="/assessment_forms" element={<Assessment_forms />} /> */}
            {/* <Route path="/reports" element={<Reports />} />
            <Route path="/activity" element={<Activity />} />
            <Route path="/medical-history" element={<MedicalHistory />} />
            <Route path="/referrals" element={<Referrals />} />
            <Route path="/" element={<Dashboard />} /> Default route */} 
          {/* </Routes> */}
        </div>
      </div>
    </Router>
  );
};

export default App;
