import React from 'react';
import { useNavigate } from 'react-router-dom';
import './welcomepage.css';
import welcomeimg from '../../assets/welcomepng.png';
import postcarelogo from '../../assets/postcare.png';

const ProviderWelcomePage = () => {
    const navigate = useNavigate();

    return (
      <div className="welcome-page-container">
        {/* Left Section */}
        <div className="left-section">
          <div className="logo">
            <img src={postcarelogo} alt="logo" />
          </div>
          <div className="welcome-content">
            <img src={welcomeimg} alt="Welcome" className="welcome-image" />
            <p className="welcome-text">
                Reduce administrative tasks, focus more on patient care. Harness the power of Augmented Clinical Intelligence to lighten your workload.
            </p>
            <h2>Coming Soon...</h2>
          </div>
        </div>

        {/* Right Section */}
        <div className="right-section" style={{ 
            position: 'relative', 
            minHeight: '100vh', 
            overflow: 'hidden', 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center', 
            paddingBottom: '3rem' // added space for bottom links
            }}>
          <h3>Welcome</h3>
          <p>Log in with your account credentials to get started</p>
          <div className="button-group">
            <button
              className="btn"
              onClick={() => navigate('')}
            >
              Log in
            </button>
            <button
              className="btn secondary"
              onClick={() => navigate('')}
            >
              Sign up
            </button>
          </div>
           {/* /* Terms and Privacy Policy Links */} 
           <div style={{
            position: 'absolute',
            bottom: '1rem',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            gap: '10px',
            fontSize: '0.875rem',
            overflow: 'hidden' // prevent overflow of links
          }}>
            <a href="/underdevelopment" style={{ textDecoration: 'none', color: 'white' }}>Terms of Use</a>
            <span>|</span>
            <a href="/underdevelopment" style={{ textDecoration: 'none', color: 'white' }}>Privacy Policy</a>
          </div>
        </div>
      </div>
    );
};

export default ProviderWelcomePage;
