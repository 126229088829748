import React from 'react';
import postcarelogo from '../assets/postcare.png';
import underdevelopment from '../assets/underdevelopment.jfif'
import { useNavigate } from 'react-router-dom';
import workinprogress from '../assets/barwork.png'
import websiteundercontruction from '../assets/website-under-construction.jpg'
function UnderDevelopmentPage() {

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            position: 'relative',
            backgroundColor: 'white'
        }}>
            <img 
                src={postcarelogo} 
                alt="Postcare Logo" 
                style={{
                    position: 'absolute',
                    top: '4%',
                    left: '2%',
                    height: '8%'
                }}
            />

            {/* Main Heading */}
            <h1 style={{
                fontSize: '3rem',
                fontWeight: 'bold',
                color: '#333',
                marginBottom: '30%',
            }}>
                Under Development
            </h1>

            {/* Placeholder Image */}
            <div style={{
                position: 'absolute',
                bottom: '5%',
                width: '70%',           // Ensures full width
                display: 'flex',          // Enables flexbox
                justifyContent: 'center', // Centers the image horizontally
                textAlign: 'center'
            }}>
                <img 
                    src={websiteundercontruction}
                    alt="Under Development Illustration" 
                    style={{
                        maxWidth: '70%',   // Responsive size
                        height: 'auto'
                    }}
                />
            </div>
            
        </div>
    );
}

export default UnderDevelopmentPage;
