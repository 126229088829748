import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import postcarelogo from '../assets/postcare.png';
import axios from 'axios'; // Ensure this path is correct
import { FaCheckCircle } from 'react-icons/fa';
import  './css/resetpassword.css';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import TempLogin from './TempLogin';

const emailSchema = z.object({
  emailPrefix: z
    .string()
    .nonempty({ message: 'Email prefix is required' })
    .regex(/^[^@]+$/, { message: 'Please do not include "@" symbol' })
    .min(3, { message: 'Minimum 3 characters required for email prefix' })
});
const ResetNewPassword = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm({
      resolver: zodResolver(emailSchema),
    });
  const [showPopup, setShowPopup] = useState(false);
  const [email, setEmail] = useState('');
 

  const onSubmit = async (data) => {
    try {
      const userEmail = `${data.emailPrefix}@gmail.com`;
      setEmail(userEmail)
      const response = await axios.post(`${process.env.REACT_APP_BASEURL}/auth/forgotpass`, {email: userEmail}, {
        withCredentials: true
      })
      if (response.status===201) {
        setShowPopup(true);
      } else {
        alert('Reset password failed. Please try again.');
      }
    } catch (error) {
      alert('An error occurred during the reset process. Please try again.');
    }
  };
  const resendVerificationEmail = async (data) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASEURL}/auth/sendlink`, data, {
        withCredentials: true
      })
      if (response.status===201) {
        alert('Verification email sent again to your email address.');
      } else {
        alert('Failed to resend verification email. Please try again.');
      }
    } catch (error) {
      alert('An error occurred. Please try again.');
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'white' , padding:'1rem'}}>
      <h2 style={{ textAlign: 'center', marginBottom: '1.5rem', color: '#2B60E6', fontWeight: 'bold', fontSize: '30px', marginTop:'-195px' }}>
        Reset Your Password
      </h2>
      <p style={{marginTop:'5px',marginBottom:'20px', color:'gray'}}>Please provide the email address that you used to sign up your account.</p>

      {/* Popup for success message */}
      {showPopup ? (
        <div className="wrap" style={{ marginTop: '200px' }}>
          <div style={{
            position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -60%)',
            backgroundColor: 'white', padding: '1rem', borderRadius: '10px', textAlign: 'center',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)', height: 'auto', marginTop: '50px'
          }}>
            <FaCheckCircle color="green" size={40} style={{ margin:'20px auto' }} />
            <h3>Check Your Email</h3>
            <p>A reset link has been sent to your email. Use it to reset your password or continue with the temporary password provided.</p>
            <div style={{ marginTop: '40px', display: 'flex', justifyContent: 'center', gap: '1rem' }}>
              <button onClick={() => navigate('/reset-altverify', {state: {userEmail: email} })} style={{
                padding: '0.75rem 1.5rem',
                backgroundColor: '#2B60E6',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                fontWeight: 'bold',
                cursor: 'pointer',
              }}>Reset</button>
            </div>
          </div>
        </div>
      ) : ( 
    
          <form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: '600px', width: '100%' }}>
            <div style={{ marginBottom: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div className="email-input-container" style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <input
                type="text"
                placeholder="Enter Your Email Prefix"
                {...register('emailPrefix', { required: 'Email prefix is required' })}
                style={{
                  width: '500px',
                  padding: '0.75rem',
                  borderRadius: '5px',
                  fontSize: '1rem',
                  outline: 'none',
                }}
              />
              <span style={{ fontSize: '1rem', marginLeft: '5px' }}>@gmail.com</span>
              {errors.emailPrefix && <span style={{ color: 'red', fontSize: '0.875rem' }}>{errors.emailPrefix.message}</span>}
            </div>
            </div>
            <button type="submit" style={{
              width: '100%',
              padding: '0.75rem',
              backgroundColor: '#2B60E6',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              fontSize: '1rem',
              fontWeight: 'bold',
              cursor: 'pointer',
              marginTop:'10px',
            }}>
              Continue
            </button>
            <p style={{ textAlign: 'center', color: 'gray', marginTop: '10px' }}>If you don't see the email, check your spam folder. Still can't find it? <button className="resend-button" onClick={resendVerificationEmail}>Resend Email</button></p>
          </form>

      )}
      <div style={{
                position: 'fixed',
                bottom: '0',
                left: '0',
                right: '0',
                textAlign: 'center',
                padding: '1rem 0',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 1
              }}>
          <img className="logofooter" src={postcarelogo} alt="Postcare Logo" 
            style={{ height: '30px', marginBottom: '0.5rem' }}/>
          <div style={{ fontSize: '0.875rem', display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
            <a href="/underdevelopment" style={{ color: '#2B60E6', textDecoration: 'none' }}>Terms of Use</a>
            <span>|</span>
            <a href="/underdevelopment" style={{ color: '#2B60E6', textDecoration: 'none' }}>Privacy Policy</a>
      </div>
    </div>
      </div>
  );
};

export default ResetNewPassword;
