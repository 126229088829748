import React, { useState, useEffect, useRef } from 'react';
import { FaCircleArrowUp } from "react-icons/fa6";
import './historysection.css';
import { MdCancel } from "react-icons/md";
import { FaPills, FaDiagnoses, FaHeartbeat } from 'react-icons/fa';
import ResponseFormat from './responseformat';
import axios from 'axios';
import iicon from '../../assets/iicon.png';
import { v4 as uuid } from "uuid";
import io from 'socket.io-client';
import { set } from 'date-fns';

const HistoryPreview = ({ isSidebarOpen,chatHistorys, sessionId, messages, addMessage}) => {
  const [conversations, setConversations] = useState([]);
  const [currentConversations, setCurrentConversations] = useState([]);
  const [newChatName, setNewChatName] = useState('');
  const [activeChat, setActiveChat] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [feedbacks, setFeedbacks] = useState([]);
  const [showOptions, setShowOptions] = useState(true);
  const [timer, setTimer] = useState(1800);  // 30 minutes in seconds
  const [hasQueried, setHasQueried] = useState(false);
  const [currentResponse, setCurrentResponse] = useState(''); // Buffer for response tokens
  const [messageBuffer, setMessageBuffer] = useState('');
  const [sessionExpired, setSessionExpired] = useState(false);
  const [feedback, setFeedback] = useState(0);
  const [clientId, setClientId] = useState(() => uuid());
  const [isNavbarShrunk, setIsNavbarShrunk] = useState(false);
  const [inputHeight, setInputHeight] = useState(40);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [chatBodyHeight, setChatBodyHeight] = useState(0);
  const [sessionRes, setSessionRes] = useState('');
  const [task, setTask] = useState('Medication');
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [initialChatName, setInitialChatName] = useState('');
//   const [messages, setMessages] = useState([]);  // Assuming messages are in state

  const { userEmail } = Location.state || {};
  const username = userEmail?.split('@')[0] || 'User';

  const chatBodyRef = useRef(null);
  const textareaRef = useRef(null);
  const topBarRef = useRef(null);
  const chatInputRef = useRef(null);
  const socketRef = useRef(null); // Define socketRef using useRef

  useEffect(() => {
    // Example condition when navbar shrinks (adjust as per your logic)
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      if (window.innerWidth < 1024) {
        setIsNavbarShrunk(true);
      } else {
        setIsNavbarShrunk(false);
      }

      const topbarHeight = document.querySelector('.top-bars-container').offsetHeight;
      const inputHeight = document.querySelector('.chat-input-container').offsetHeight;
      const scrollbodyHeight = window.innerHeight - (topbarHeight + inputHeight);

      const chatBody = document.querySelector('.chat-body');
      // if (chatBodyRef.current) {
        // chatBodyRef.current.style.maxHeight = `${chatBodyHeight}px`;
        if (chatBody) {
          chatBody.style.maxHeight = `${scrollbodyHeight}px`;
      }
    };

    window.addEventListener('resize', handleResize);
  
    // Call the handleResize function on component mount to apply initial maxHeight
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [chatBodyHeight]);

  useEffect(() => {
    // Function to calculate available space for chat-body
    const calculateChatBodyHeight = () => {
      const topBarHeight = topBarRef.current?.offsetHeight || 0;
      const inputHeight = chatInputRef.current?.offsetHeight || 0;
      const availableHeight = window.innerHeight - inputHeight;
      setChatBodyHeight(availableHeight);
    };

    // Initial calculation and on window resize
    calculateChatBodyHeight();
    window.addEventListener('resize', calculateChatBodyHeight);

    // Cleanup
    return () => window.removeEventListener('resize', calculateChatBodyHeight);
  }, [topBarRef,]);

// Sync chatHistory with chatHistorys prop whenever chatHistorys changes
useEffect(() => {
    if (chatHistorys && chatHistorys.length) {
      setChatHistory(chatHistorys);
    }
  }, [chatHistorys]);

  

  useEffect(() => {
    socketRef.current = io(process.env.REACT_APP_SOCKET_URL,{
      transports: ['websocket','polling'],
    })

    socketRef.current.on('connect', () => {
      console.log('WebSocket connected');
      socketRef.current.emit('register', { clientId });
    });

    socketRef.current.on('response', (message) => {
      // console.log(message)
      if (message.id === clientId) {
        const words = message.response.split(' ');

        words.forEach((word, index) => {
          setTimeout(() => {
            setMessageBuffer(prevBuffer => {
              const updatedBuffer = prevBuffer + ' ' + word;

              if (updatedBuffer.includes('<|eot_id|>')) {
                const [fullMessage, ...remainingBuffer] = updatedBuffer.split('<|eot_id|>');
                const trimmedMessage = fullMessage.trim();

                setChatHistory(prevHistory => [
                  ...prevHistory,
                  {
                    id: prevHistory.length + 1,
                    message: trimmedMessage,
                    type: 'bot',
                    timestamp: new Date()
                  }
                ]);
                setCurrentResponse('');
                return remainingBuffer.join('<|eot_id|>');
              }

              setCurrentResponse(prevResponse => prevResponse + ' ' + word);

              return updatedBuffer;
            });
          }, index * 5);
        });
      }
    });

    socketRef.current.on('disconnect', () => {
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, [clientId]);

  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [chatHistory]);
  
  
  const loadChatFromLocalStorage = (sessionId) => {
    const savedChat = localStorage.getItem(`chat_${sessionId}`);
    return savedChat ? JSON.parse(savedChat) : [];
  };


  useEffect(() => {
    if (sessionId) {
      const chatHistoryForSession = loadChatFromLocalStorage(sessionId);
      setChatHistory(chatHistoryForSession); // Update state with chat history
    }
  }, [sessionId])


useEffect(() => {
  // Fetch chat history when the component mounts or when the active chat changes
  const fetchChatHistory = async () => {
    try {
      // console.log(sessionRes.data.chatId);
      // const response = await axios.get(`${process.env.REACT_APP_BASEURL}/llm/chathistory?chatId=${sessionRes.data.chatId}`, { withCredentials: true });
      // setChatHistory(response.data);
      // if(response.data.length == 0 && chatHistory.length==0){
      //   setChatHistory([]);
      // }
     
    } catch (error) {
      console.error('Error fetching chat history:', error);
    }
  };

  if (sessionRes) {
    fetchChatHistory();
  }
}, [sessionRes]);


  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(interval);
          setSessionExpired(true);
        }
        return prevTimer - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const cancelTimer = () => setTimer(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentConversations([]);
    }, 86400000);

    return () => clearInterval(interval);
  }, [currentConversations]);

 
  const scrollToBottom = () => {
    // const chatBody = document.getElementById("chatBody"); // Ensure this ID is correct
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory, feedbacks, currentResponse]);

//     if (chatBodyRef.current) {
//       chatBodyRef.current.scrollTo({
//         top: chatBodyRef.current.scrollHeight,
//         behaviour:'smooth'
//       });
//   };
// }

// useEffect(() => {
//   if (messages.length) {
//     scrollToBottom();  // Ensure scrolling to bottom on every message update
//   }
// }, [messages]); 


 
  const startNewChat = async (initialMessage = '') => {
    const response = await axios.post(`${process.env.REACT_APP_BASEURL}/llm/start-session`, { initialMessage }, { withCredentials: true });
    const chatId = response.data.chatId;
    const trimmedName = newChatName.trim();
    const chatName = trimmedName === '' ? `New Chat ${conversations.length + 1}` : (trimmedName.length > 20 ? trimmedName.substring(0, 20) + '...' : trimmedName);
    const newChat = {
      id: Date.now(),
      name: chatName,
      subheading: initialMessage.substring(0, 20),
      timestamp: new Date(),
      history: []
    };

    setConversations([...conversations, newChat]);
    setCurrentConversations([newChat]);
    setNewChatName('');
    setActiveChat(chatId);
    // setChatHistory([]);
    return response; 
  };


  const sendMessageToBot = async (message) => {
    const userMessage = {
      id: Date.now(),
      message,
      type: 'user',
      timestamp: new Date()
    };
    // addMessage(userMessage)
    setChatHistory((prevHistory) => [...prevHistory, userMessage]);

    let chatId= activeChat;
  
  
    if (!chatId) {
      try{ 
      const sessionRes = await startNewChat(message);
      if (sessionRes?.data?.chatId) {
        chatId = sessionRes.data.chatId;
      } else {
        console.error("Session response data is undefined");
        return; // Exit if sessionRes.data is not defined
      }

  } catch (error) {
    console.error('Error querying LLM model:', error);
    return;
  }
}; 


  // Send message through WebSocket if available
  if (socketRef.current?.readyState === WebSocket.OPEN && chatId) {
    socketRef.current.send(JSON.stringify({ 
      query: message, 
      id: clientId, 
      chatId 
    }));
  }

  try {
    // Make API request
    const response = await axios.post(
      `${process.env.REACT_APP_BASEURL}/llm/get-response`,
      { 
        query: message, 
        id: clientId, 
        chatId, 
        option: task 
      },
      { withCredentials: true }
    );

    // Handle bot response
    if (response?.data?.answer) {
      const botResponse = {
        id: Date.now(),
        message: response.data.answer,
        type: 'bot',
        timestamp: new Date()
      };

      // Set current response for streaming display
      // setCurrentResponse(response.data.answer);
      // Update chat history with bot response
      setChatHistory(prevHistory => {
        const newHistory = [...prevHistory, botResponse];
        // Save to localStorage after bot response
        if (activeChat) {
          localStorage.setItem(`chat_${activeChat}`, JSON.stringify(newHistory));
        }
        return newHistory;
      });
      setCurrentResponse(response.data.answer);

      // Add bot message to parent component if needed
      addMessage(botResponse);
      setCurrentResponse("");
    }
  } catch (error) {
    console.error('Error querying LLM model:', error);
    // Optionally add error message to chat
    const errorMessage = {
      id: Date.now(),
      message: "Sorry, there was an error processing your request.",
      type: 'bot',
      timestamp: new Date()
    };
    setChatHistory(prevHistory => [...prevHistory, errorMessage]);

    addMessage(errorMessage);
  }
};
useEffect(() => {
  if (activeChat && chatHistory.length > 0) {
    localStorage.setItem(`chat_${activeChat}`, JSON.stringify(chatHistory));
  }
}, [chatHistory, activeChat]);

  const handleSendMessage = () => {
    if (newChatName.trim() === '') return;
    setInitialChatName((prev) => {
      if (!prev) { // Only update if it's the first message
        const trimmedMessage = newChatName.trim();
        const newName = trimmedMessage.substring(0, 20) + (trimmedMessage.length > 20 ? '...' : '');
        return newName;
      }
      return prev; // If a name already exists, don't update
    });
    sendMessageToBot(newChatName);
    setNewChatName('');
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Prevent default Enter behavior
      handleSendMessage();
    }
  };

  const handleFeedback = (feedback) => {
    setFeedbacks([...feedbacks, feedback]);
  };

  const marginLeftValue = screenWidth >= 1200 ? (isSidebarOpen ? '255px' : '45px') : '';

  return (
    <div className="ai-companion-container" style={{ marginLeft: marginLeftValue }} >
      <div className={`bg-main ${isSidebarOpen ? 'sidebar-open' : 'sidebar-collapsed'}`} style={{ backgroundColor: '#F8F7F7' }}>
        <div className="top-bars-container" style={{ backgroundColor: 'rgb(248, 247, 247)', padding: '0px 0px 5px' }}>
          <div className="notification-bar">
            <img src={iicon} alt="ibutton" className="icon-info-circle" ></img>
            <div className="notification-content">
              <span className="notification-text" >You are on a free trial, each session is valid for 30 mins. Your current session will end in -</span>
              <span className="timer-text" style={{ color: timer <= 300 ? 'red' : 'black' }}>{formatTime(timer)} minutes</span>
            </div>
            <button className="cancel-btn" style={{ marginLeft: '14px' }} onClick={() => setInterval(0)} ><MdCancel /></button>
          </div>
          {/* {sessionExpired && (
          <div className="session-expired-popup">
            <h2>Session Expired</h2>
            <p>Free trial sessions are only valid for 30 minutes. Please log in again.</p>
            <button className="login-button" onClick={() => window.location.href = '/login'}>Login</button>
            <div className="feedback">
              <p>Rate your session:</p>
              {[1, 2, 3, 4, 5].map((star) => (
                <spanse
                  key={star}
                  style={{ color: star <= feedback ? 'yellow' : 'gray', cursor: 'pointer' }}
                  onClick={() => handleFeedback(star)}
                >
                  ★
                </span>
              ))}
            </div>
          </div>
        )} */}
          <div className="care-bar-container"></div>
          <div className="care-bar">
            <h1 className='Slogan' >Always There, Always Care.....</h1>
            <p className='Slogan_descp' > Your personal AI Companion available 24/7 to answer your queries</p>
          </div>
        </div>
          <div className="chat-body" ref={chatBodyRef} style={{ overflowY: 'auto', padding: '1rem', maxHeight:'356px'}}>
          {chatHistorys.length === 0 ? (
          <p>No chat history available for this session.</p>
        ) : (
            chatHistory.map((message, index) => (
              <div
                key={index}
                className={`message ${message.type}`}
              >
                {/* <span> {message.timestamp}:</span>
                {message.message} */}
                <ResponseFormat
                 response={message.message}
                  message={message}
                  onFeedback={handleFeedback}
                  scrollToBottom={scrollToBottom}
                  type={message.role === 'assistant' ? 'bot' : 'user'}
                  timestamp={message.timestamp}
                />
              </div>
            ))
        )}
            {currentResponse && (
            <div className="message bot">

              <ResponseFormat
                response={currentResponse}

                // key={chatHistory.length}
                message={{ id: chatHistory.length + 1, message: currentResponse.trim(), type: 'bot', timestamp: new Date() }}
                onFeedback={handleFeedback}
                scrollToBottom={scrollToBottom}
                type="bot"
                timestamp={new Date()}
              />
            </div>  
            )}
          </div>
          {/* <div className="feedback-section">
              {feedbacks.map((feedback, index) => (
                <div key={index} className="feedback-item">
                  <p>{feedback}</p>
                </div>
              ))}
            </div> */}
        </div>
        <div className="chat-input-container" ref={chatInputRef} style={{ marginLeft: screenWidth >= 1200 ? (isSidebarOpen ? '5%' : '15%') : '' }} >
          <div
            className="chat-input-section"
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '10px',
              position: 'relative',
            }}
          >
            <div style={{ flexGrow: 1, position: 'relative' }}>
              <textarea
                ref={textareaRef}
                value={newChatName}
                onChange={(e) => setNewChatName(e.target.value)}
                className="chat-input"
                placeholder="Type Here..."
                onKeyDown={handleKeyPress}
                style={{
                  width: '100%',
                  padding: '10px',
                  borderRadius: '20px',
                  outline: 'none',
                  fontSize: '15px',
                  resize: 'none', // Disable manual resizing by the user
                  overflowY: 'auto', // Enable scrolling when content exceeds max height
                  maxHeight: '80px', // Max height for 3 lines (approximately 80px for 3 lines)
                  paddingRight: '40px', // Add padding to avoid overlap with the send button
                  boxSizing: 'border-box', // Ensure padding is included in the size calculation
                  height: 'auto', // Dynamically adjust height based on content
                }}
                rows={1} // Start with 1 row by default
                onInput={(e) => {
                  // Dynamically adjust the height based on content
                  e.target.style.height = 'auto'; // Reset height
                  e.target.style.height = `${Math.min(e.target.scrollHeight, 80)}px`; // Restrict max height to 80px (~3 lines)
                }}
              />
            </div>
            <FaCircleArrowUp
              style={{
                position: 'absolute',
                right: '28px',
                color: 'black',
                cursor: 'pointer',
                fontSize: '24px',
              }}
              onClick={handleSendMessage}
            />
          </div>
          {/* <button
      className="send-button"
      onClick={handleSendMessage}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'black',
        color: 'white',
        border: 'none',
        borderRadius: '50%',
        width: '40px',
        height: '40px',
      }}
    >
      <FaCircleArrowUp />
    </button>
  </div> */}

          {/* Centered message below the input section */}
          <div
            className="experimental-message"
          // style={{
          //   textAlign: 'center',
          //   // marginTop: '10px',
          //   fontSize: '9px',
          //   color: '#666',
          // }}
          >
            This is an experimental conversational AI. It can make mistakes.
          </div>
        </div>

      </div>
  );
};

export default HistoryPreview;